.student-vacancy-clickable-check {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.student-vacancy__detail-subtitle {
    display: block;
    font-size: 14px;
    margin-top: -5px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    word-break: break-word;
}

.student-vacancy__datepicker {
    height: 35px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}